import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./styles.module.scss";

const INPUT_TYPES = {
  icon: styles.icon,
  iconRight: styles.iconRight,
  message: styles.message,
};

const Input = ({
  className,
  placeholder,
  name,
  value,
  htmlType,
  type,
  disabled,
  required,
  maxLength,
  onChange,
}) => (
  <input
    placeholder={placeholder}
    name={name}
    value={value}
    type={htmlType}
    disabled={disabled}
    required={required}
    maxLength={maxLength}
    className={classNames(styles.input, INPUT_TYPES[type], className)}
    onChange={onChange}
    onKeyDown={(event) => {
      if (event.key === "Enter" && event.value) onChange(event);
    }}
  />
);

Input.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  htmlType: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
};

Input.defaultProps = {
  className: undefined,
  placeholder: undefined,
  value: undefined,
  htmlType: "text",
  type: "",
  disabled: false,
  required: false,
  maxLength: null,
  onChange: () => {},
};

export default Input;
