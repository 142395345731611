export const initialState = {
  enrolmentFilters: { priority: null, course: null, tutor: null },
  enrolmentsSearch: "",
  enrolmentsCurrent: null,
  enrolments: [],
  messages: [],
  messagesInfo: {},
  highlightedMessage: null,
  lastMessage: null,
  lastMessageToRequest: null,
  priorityMessagesCounter: 0,
  cacheData: {},
  socket: null,
};

export const DEFAULT_MESSAGE_PARAMS = {
  page: 1,
  page_size: 30,
  search: null,
};

const pushLastMessageToList = (messages, info, payload) => {
  let msg = [...messages];

  // if enrolment has more content to request,
  // remove the last message to prevent duplication
  if (info.next) {
    msg = msg.map((page, ind) => {
      if (ind !== msg.length - 1) return page;
      return page.slice(0, -1);
    });
  }

  // sent message needs to be in the first page to be the first message
  msg[0] = [{ ...payload }, ...msg[0]];

  return msg;
};

const getEnrolmentDate = (enrolment) => new Date((enrolment.last_message || enrolment).created);

export const reducer = (state, action) => {
  switch (action.type) {
    case "enrolmentS_FILTER": {
      const enrolmentFilters = {
        ...state.enrolmentFilters,
        [action.payload.field]: action.payload.value,
      };

      // resets new message counter when clear filters
      let priorityMessagesCounter = state.priorityMessagesCounter;
      const filterWithValue = Object.values(enrolmentFilters).some((value) => value);
      if (!filterWithValue) priorityMessagesCounter = 0;

      return {
        ...state,
        priorityMessagesCounter,
        enrolmentFilters,
      };
    }
    case "enrolmentS_SEARCH": {
      // resets new message counter when clear search
      let priorityMessagesCounter = state.priorityMessagesCounter;
      if (!action.payload) priorityMessagesCounter = 0;

      return {
        ...state,
        priorityMessagesCounter,
        enrolmentsSearch: action.payload,
      };
    }
    case "enrolmentS_CURRENT":
      return {
        ...state,
        enrolmentsCurrent: action.payload,
      };
    case "enrolmentS_CURRENT_COUNTER": {
      const enrolments = state.enrolments.map((enrolment) => {
        const { id, priority_counter } = enrolment;

        if (id === action.payload.enrolment) {
          if (action.payload.priority === "HIGH") {
            enrolment.priority_counter = priority_counter + 1;
            enrolment.priority = "HIGH";
          } else enrolment.priority_counter = priority_counter - 1;
        }

        return enrolment;
      });

      const currentenrolment = state.cacheData[action.payload.enrolment];
      currentenrolment.messages.map((page) =>
        page.map((message) => {
          if (message.id === action.payload.message) {
            message.priority = action.payload.priority;
          }
          return message;
        })
      );

      const cacheData = {
        ...state.cacheData,
        [action.payload.enrolment]: currentenrolment,
      };

      return {
        ...state,
        enrolments,
        cacheData,
      };
    }
    case "enrolmentS": {
      let updatedLastMessage = {};
      if (action.payload.updatedLastMessage) updatedLastMessage = { lastMessageToRequest: null };

      return {
        ...state,
        ...updatedLastMessage,
        enrolments: action.payload.enrolments,
      };
    }
    case "CACHED_MESSAGES":
      return {
        ...state,
        messages: action.payload.messages,
        messagesInfo: action.payload.info,
      };
    case "MESSAGES":
      return {
        ...state,
        messages: [action.payload.results],
        messagesInfo: action.payload.info,
        cacheData: {
          ...state.cacheData,
          [action.payload.info.params.enrolment]: {
            messages: [action.payload.results],
            info: action.payload.info,
          },
        },
      };
    case "MESSAGES_MERGE":
      return {
        ...state,
        messages: [...state.messages, action.payload.results],
        messagesInfo: action.payload.info,
        cacheData: {
          ...state.cacheData,
          [action.payload.info.params.enrolment]: {
            messages: [...state.messages, action.payload.results],
            info: action.payload.info,
          },
        },
      };
    case "MESSSAGES_HIGHLIGHT":
      return {
        ...state,
        highlightedMessage: action.payload,
      };
    case "MESSAGES_RECEIVED": {
      let cache = { ...state.cacheData };
      let cachedenrolment = cache[action.payload.enrolment] || {};

      let alreadyRequested = false;

      // set received message as its enrolment preview
      // and set message time as modified enrolment time to push it to the top of the list
      const enrolments = state.enrolments.map((enrolment) => {
        if (enrolment.id === action.payload.enrolment) {
          enrolment.last_message = action.payload;
          alreadyRequested = true;

          // only received messages can reopen window and update counter
          if (action.payload.direction === "received") {
            enrolment.priority_counter += 1;

            // reopen the window if closed
            enrolment.message_window_open = true;

            if (action.payload.priority === "HIGH") {
              enrolment.priority = "HIGH";
            }
          }
        }
        return enrolment;
      });

      // does the same with cached version
      if (cachedenrolment.messages) {
        cachedenrolment.messages = pushLastMessageToList(
          cachedenrolment.messages,
          cachedenrolment.info,
          action.payload
        );
      }

      let messages = state.messages;

      if (state.enrolmentsCurrent && state.enrolmentsCurrent.id === action.payload.enrolment) {
        messages = cachedenrolment.messages;
      }

      if (alreadyRequested) {
        const cachedCurrent = cachedenrolment.messages
          ? {
              [action.payload.enrolment]: {
                ...state.cacheData[action.payload.enrolment],
                messages: cachedenrolment.messages,
              },
            }
          : {};

        return {
          ...state,
          enrolments: enrolments.sort((a, b) => getEnrolmentDate(b) - getEnrolmentDate(a)),
          messages,
          lastMessage: action.payload,
          cacheData: {
            ...state.cacheData,
            ...cachedCurrent,
          },
        };
      }

      return {
        ...state,
        lastMessageToRequest: action.payload,
      };
    }
    case "PRIORITY_MESSAGE_COUNT":
      return {
        ...state,
        priorityMessagesCounter: state.priorityMessagesCounter + 1,
      };
    case "PRIORITY_MESSAGE_COUNT_INITIAL":
      return {
        ...state,
        priorityMessagesCounter: action.payload,
      };
    case "NEW_MESSAGE": {
      const { enrolmentsSearch, enrolmentFilters } = state;
      const hasFilters = Object.values(enrolmentFilters).some((value) => value);

      let currentState = state;

      // don't request new message enrolment if there's a filter or search active
      // instead add to the counter if has high priority

      // using actions or adding this logic to /home page generates loop errors
      // keeps opening and closing on page re-render (watching state filters/search update)
      if (action.payload && action.payload.priority === "HIGH") {
        currentState = reducer(state, { type: "PRIORITY_MESSAGE_COUNT", payload: action.payload });
      }

      if (enrolmentsSearch || hasFilters) {
        return currentState;
      }

      return reducer(currentState, { type: "MESSAGES_RECEIVED", payload: action.payload });
    }
    case "RESET_FILTERS":
      return {
        ...state,
        enrolmentFilters: initialState.enrolmentFilters,
        enrolmentsSearch: initialState.enrolmentsSearch,
        priorityMessagesCounter: 0,
      };
    case "SOCKET":
      return {
        ...state,
        socket: action.payload,
      };
    default:
      return state;
  }
};
