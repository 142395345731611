import api from "interfaces/http";

const request = async ({ path, params, callback, noPagination }) => {
  console.info(">> Requesting:", path);

  const { data, status } = await api.get(path, { params });

  if (status < 400) {
    const { count, next, previous } = data;

    if (data.results && data.results.length && (params.page || noPagination)) {
      callback({
        results: data.results || [],
        totalPriorities: data.total_priorities || 0,
        info: { count, next, previous, params },
      });
    } else if (data.id) {
      callback({ results: [data], info: { count, next, previous, params } });
    }
  }
};

export default request;
