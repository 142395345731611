import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import { isAuthenticated } from "services/auth";

const PrivateRoute = ({ path, exact, component: Component }) => (
  <Route
    exact={exact}
    path={path}
    render={({ history, location, match, staticContext, ...routingProps }) =>
      !isAuthenticated() ? (
        <Redirect to="/login" />
      ) : (
        <Component
          history={history}
          location={location}
          match={match}
          staticContext={staticContext}
          {...routingProps}
        />
      )
    }
  />
);

PrivateRoute.propTypes = {
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  component: PropTypes.func.isRequired,
  exact: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  exact: false,
};

export default PrivateRoute;
