import React, { useState, useEffect } from "react";

import Input from "components/atoms/input";
import { useContext } from "context/socket";
import { ReactComponent as Icon } from "images/icons/search.svg";

import styles from "./styles.module.scss";

let timeout = 0;

const SearchContacts = () => {
  const { state, dispatch } = useContext();
  const { enrolmentsSearch } = state;

  const [currentValue, setCurrentValue] = useState("");

  const handleSearch = ({ target }) => {
    const { value } = target;
    setCurrentValue(value);

    const search = () => {
      dispatch({ type: "enrolmentS_SEARCH", payload: value });
    };

    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(search, 400);
  };

  useEffect(() => setCurrentValue(enrolmentsSearch), [enrolmentsSearch]);

  return (
    <div className={styles.bar}>
      <div className={styles.search}>
        <Input
          name="search"
          type="icon"
          disabled={state.enrolmentsLoading}
          value={currentValue}
          onChange={handleSearch}
          placeholder="Buscar código do aluno..."
        />
        <Icon className={styles.icon} />
      </div>
    </div>
  );
};

export default SearchContacts;
