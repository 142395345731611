import React, { useState, useEffect, useCallback } from "react";
import InfiniteScroll from "react-infinite-scroller";
import classNames from "classnames";

import Contact from "components/molecules/contact";
import { useContext, getActions } from "context/socket";
import get from "utils/request";
import { hasNoFiltersApplied } from "utils/filter";

import styles from "./styles.module.scss";
import { getUser } from "utils/store";

const PAGE_SIZE = 30;
const INITIAL_INFO = { count: 0, next: null, previous: null };

let amountOfRequests = 0;

const ContactList = () => {
  const { state, dispatch } = useContext();
  const {
    enrolmentsSearch,
    enrolments,
    enrolmentsCurrent,
    lastMessageToRequest,
    enrolmentFilters,
    cacheData,
    priorityMessagesCounter,
  } = state;

  const { setCurrentenrolment } = getActions(dispatch);
  const [contacts, setContacts] = useState([]);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({ course: null, priority: null, tutor: null });

  const [info, setInfo] = useState(INITIAL_INFO);

  const [currentPage, setCurrentPage] = useState(1);

  const setTotalPriority = useCallback((priority) => {
    dispatch({ type: "PRIORITY_MESSAGE_COUNT_INITIAL", payload: priority });
  });

  const updateContacts = useCallback((data) => {
    setContacts((prev) => [...(prev || []), ...data]);
  }, []);

  const handleSelect = (props) => () => {
    setCurrentenrolment(props, cacheData[props.id]);
  };

  const request = useCallback(
    ({ page, updateInfo, updateContacts }) => {
      amountOfRequests += 1;

      const path = "/enrolments/";
      const { course, priority, tutor } = enrolmentFilters;
      const params = {
        page_size: PAGE_SIZE,
        page,
        ...(enrolmentsSearch ? { search: enrolmentsSearch } : {}),
        ...(course ? { courses: course } : {}),
        ...(priority ? { priority } : {}),
        ...(tutor ? { tutor } : {}),
      };
      const user = getUser();

      const callback = ({ results, totalPriorities, info }) => {
        updateInfo(info);
        updateContacts(results);
        setTotalPriority(totalPriorities);
      };
      if (hasNoFiltersApplied(state) && user.is_superuser) return;

      if (page) get({ path, params, callback });
    },
    [enrolmentFilters, enrolmentsSearch, setTotalPriority, state]
  );

  const handleLoadMore = useCallback(() => {
    let page = null;
    let retryAttempts = 0;

    if (info.next) {
      page = info.next.match(/(page=\d*)/);
      page = Number(page[0].replace(/\D/g, ""));
    }

    if (page !== currentPage) {
      setCurrentPage(page);

      const tryRequest = () => {
        retryAttempts += 1;

        if (retryAttempts > 20) return; // 10s trying to get context data
        if (contacts.length < PAGE_SIZE * (currentPage - 1)) return setTimeout(tryRequest, 500);
        return request({ page, updateInfo: setInfo, updateContacts });
      };

      tryRequest();
    }
  }, [contacts.length, currentPage, info.next, request, updateContacts]);

  const getRecentenrolment = useCallback(() => {
    if (!lastMessageToRequest) return;

    const handleenrolment = ({ results }) => {
      dispatch({
        type: "enrolmentS",
        payload: { enrolments: [...results, ...enrolments], updatedLastMessage: true },
      });
    };

    get({
      path: `/enrolments/${lastMessageToRequest.enrolment}`,
      params: {},
      callback: handleenrolment,
    });
  }, [enrolments, dispatch, lastMessageToRequest]);

  const reset = useCallback(() => {
    setInfo(INITIAL_INFO);
    setCurrentPage(1);
    setContacts([]);
    request({ page: 1, updateInfo: setInfo, updateContacts });
  }, [request, updateContacts]);

  useEffect(() => {
    if (amountOfRequests === 0) {
      request({ page: currentPage, updateInfo: setInfo, updateContacts });
    }
  }, [contacts.count, currentPage, request, updateContacts]);

  useEffect(() => {
    if (search !== enrolmentsSearch) {
      setSearch(enrolmentsSearch);
      reset();
    }
  }, [enrolmentsSearch, reset, search]);

  useEffect(() => {
    const { course, priority, tutor } = enrolmentFilters;

    if (filters.course !== course || filters.priority !== priority || filters.tutor !== tutor) {
      setFilters(enrolmentFilters);
      reset();
    }
  }, [enrolmentFilters, filters.course, filters.priority, filters.tutor, reset]);

  useEffect(() => {
    dispatch({ type: "enrolmentS", payload: { enrolments: contacts } });
  }, [contacts, dispatch]);

  useEffect(() => {
    getRecentenrolment();
  }, [getRecentenrolment]);

  useEffect(() => {
    return () => {
      amountOfRequests = 0;
    };
  }, []);

  return (
    <div
      className={classNames(styles.contactList, { [styles.smaller]: priorityMessagesCounter > 0 })}
    >
      {enrolments.length > 0 && (
        <InfiniteScroll
          threshold={100}
          loadMore={handleLoadMore}
          useWindow={false}
          hasMore={!!info.next}
          loader={<div className="loader" key={0} />}
        >
          {enrolments.map((props) => (
            <Contact
              key={props.id}
              {...props}
              isActive={props.id === (enrolmentsCurrent || {}).id}
              onClick={handleSelect(props)}
            />
          ))}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default ContactList;
