import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ReactPlayer from "react-player";

import { ReactComponent as Icon } from "images/icons/play.svg";

import styles from "./styles.module.scss";

const Video = ({ url, className }) => {
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(true);

  return (
    <div className={classNames(styles.container, className)}>
      <ReactPlayer
        width="100%"
        height="100%"
        url={url}
        playing={playing}
        controls={true}
        className={styles.player}
        onReady={() => setLoading(false)}
        onEnded={() => setPlaying(false)}
      ></ReactPlayer>
      <button
        onClick={() => setPlaying((oldState) => !oldState)}
        className={classNames(styles.button, {
          [styles.buttonActive]: !playing,
          [styles.buttonLoading]: loading,
        })}
      >
        <Icon className={styles.icon} />
      </button>
    </div>
  );
};

Video.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Video.defaultProps = {
  className: undefined,
};

export default Video;
