import React from "react";

import styles from "./styles.module.scss";

const FiltersMessage = () => {
  return (
    <div className={styles.container}>
      <span className={styles.text}>Selecione ao menos um filtro</span>
    </div>
  );
};

export default FiltersMessage;
