import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import dayJS from "dayjs";
import ReactMarkdown from "react-markdown";

import Badge from "components/atoms/badge";
import Text from "components/atoms/text";
import { ReactComponent as Student } from "images/icons/student.svg";

import styles from "./styles.module.scss";

const PREVIEW_LABELS = { image: "Imagem", video: "Video", audio: "Áudio" };

const Contact = ({
  student,
  student_token,
  last_message,
  priority,
  priority_counter,
  isActive,
  modified,
  onClick,
}) => {
  const lastMessage = last_message || {};
  const contentText = lastMessage.content_text || "";
  const previewTextPrefix = PREVIEW_LABELS[lastMessage.message_type] || "";

  const previewText =
    previewTextPrefix && contentText
      ? `${previewTextPrefix}: ${contentText.substr(0, 32)}`
      : previewTextPrefix || contentText.substr(0, 40);

  return (
    <div
      tabIndex={0}
      className={classNames(styles.link, { [styles.linkActive]: isActive })}
      onClick={onClick}
      onKeyDown={(event) => {
        if (event.key === "Enter") onClick(event);
      }}
    >
      <div className={styles.profile}>
        <Student className={styles.icon} />
      </div>
      <div className={styles.preview}>
        <Text weight={600} className={styles.text}>
          <span>#{student}</span>{" "}
          {student_token && <span className={styles.token}>{student_token}</span>}
        </Text>
        <Text className={styles.message} markdown>
          {<ReactMarkdown source={previewText} transformLinkUri={() => {}} /> || "Sem preview"}
          {contentText.length > 40 && "..."}
        </Text>
      </div>
      <div className={styles.info}>
        <Text className={styles.time}>
          {dayJS(lastMessage.created || modified).format("DD/MM/YYYY")}
          <br />
          {dayJS(lastMessage.created || modified).format("HH:mm")}
        </Text>
        {priority_counter > 0 && (
          <Badge type={priority === "HIGH" ? "error" : "success"}>{priority_counter}</Badge>
        )}
      </div>
    </div>
  );
};

Contact.propTypes = {
  student: PropTypes.number.isRequired,
  student_token: PropTypes.string,
  last_message: PropTypes.shape({ content_text: PropTypes.string }),
  priority: PropTypes.string,
  priority_counter: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
  modified: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

Contact.defaultProps = {
  student_token: null,
  last_message: { content_text: "" },
  priority: null,
  isActive: false,
  onClick: () => {},
};

export default Contact;
