export const register = () => {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", function () {
      navigator.serviceWorker
        .register("sw.js")
        .then(
          (registration) => console.info("> [SW] Registration successful", registration.scope),
          (err) => console.info("> [SW] Registration failed", err)
        )
        .catch((err) => console.info("> [SW]", err));
    });
  } else {
    console.info("> [SW] Service Worker is not supported by browser.");
  }
};
