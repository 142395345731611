import React, { useState, useEffect, useCallback } from "react";
import { Redirect } from "react-router-dom";

import Header from "components/organisms/header";
import FiltersMessage from "components/molecules/filters-message";
import MessagesCounter from "components/molecules/messages-counter";
import SearchContacts from "components/molecules/search-contacts";
import ContactList from "components/organisms/contact-list";
import Chat from "components/organisms/chat";
import { useContext } from "context/socket";
import initSocket from "interfaces/websocket";
import { hasNoFiltersApplied } from "utils/filter";
import styles from "./styles.module.scss";
import { getUser } from "utils/store";

const Home = () => {
  const { dispatch, state } = useContext();

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [socketData, setSocketData] = useState(null);
  const handleLogout = () => setShouldRedirect(true);

  const handleMessage = useCallback(
    (newMessage) => {
      dispatch({ type: "NEW_MESSAGE", payload: newMessage });
    },
    [dispatch]
  );

  const setSocket = useCallback((payload) => dispatch({ type: "SOCKET", payload }), [dispatch]);

  useEffect(() => {
    const req = initSocket(handleMessage);
    setSocket(req);
    setSocketData(req);
  }, [setSocket, handleMessage, setSocketData]);

  useEffect(() => {
    return () => {
      if (socketData) socketData.close();
    };
  }, [socketData]);

  if (shouldRedirect) return <Redirect to="/login" />;
  const user = getUser();

  return (
    <div>
      <Header onLogout={handleLogout} />
      <div className={styles.content}>
        <div className={styles.contacts}>
          <SearchContacts />
          {hasNoFiltersApplied(state) && user.is_superuser && <FiltersMessage />}
          <MessagesCounter />
          <ContactList />
        </div>
        <Chat />
      </div>
    </div>
  );
};

export default Home;
