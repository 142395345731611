import React, { useEffect } from "react";
import Analytics from "react-ga";

Analytics.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);

const withAnalytics = (Component, options = {}) => {
  const track = (page) => {
    Analytics.set({
      page,
      ...options,
    });

    Analytics.pageview(page);
  };

  const HOC = ({ location, ...props }) => {
    useEffect(() => {
      if (location && location.pathname) {
        track(location.pathname);
      }
    }, [location]);

    return <Component {...props} />;
  };

  return HOC;
};

export default withAnalytics;
