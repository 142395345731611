import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./styles.module.scss";

const WEIGHT_CLASSES = {
  400: undefined,
  600: styles.medium,
};

const Text = ({ children, weight, className, markdown }) => {
  const props = { className: classNames(styles.text, WEIGHT_CLASSES[weight], className), children };
  return markdown ? <div {...props} /> : <p {...props} />;
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
  weight: PropTypes.number,
  className: PropTypes.string,
  markdown: PropTypes.bool,
};

Text.defaultProps = {
  weight: 400,
  className: undefined,
  markdown: false,
};

export default Text;
