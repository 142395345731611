import React, { useState, useEffect, Fragment } from "react";
import dayJS from "dayjs";

import Text from "components/atoms/text";
import Input from "components/atoms/input";
import Sidebar from "components/molecules/sidebar";
import { useContext, getActions } from "context/socket";
import request from "utils/request";
import { ReactComponent as SearchIcon } from "images/icons/search.svg";

import styles from "./styles.module.scss";

let timeout = 0;

const ChatSearch = () => {
  const { state, dispatch } = useContext();
  const { enrolmentsCurrent } = state;
  const { setHighlightedMessage } = getActions(dispatch);

  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);
  const [forceClose, setForceClose] = useState(false);

  const reset = () => {
    setTimeout(() => {
      setSearch("");
      setResults([]);
      setForceClose(true);

      setTimeout(() => setForceClose(false), 100);
    }, 100);
  };

  const handleClick = (message) => () => {
    setHighlightedMessage(message);
    reset();
  };

  const callback = ({ results: searchResults, info }) => {
    setResults(searchResults);
  };

  const showHighlight = (text) => {
    if (!search || !text) return [text];

    const regex = new RegExp(`(${search})`, "gi");

    return text.split(regex).map((part, i) => (
      <span key={`text-${i}`} className={i % 2 === 1 ? styles.highlight : ""}>
        {part}
      </span>
    ));
  };

  const handleSearch = ({ target }) => {
    const { value } = target;
    setSearch(value);

    const search = () => {
      request({
        path: `/messages/`,
        params: { enrolment: enrolmentsCurrent.id, search: target.value },
        callback,
        noPagination: true,
      });
    };

    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(search, 400);
  };

  useEffect(() => {
    reset();
  }, [enrolmentsCurrent]);

  return (
    <Sidebar
      title="Buscar na Conversa"
      toggleIcon={<SearchIcon />}
      toggleClassName={styles.toggle}
      onClose={reset}
      forceClose={forceClose}
    >
      <div className={styles.field}>
        <Input name="search" type="icon" value={search} onChange={handleSearch} />
        <SearchIcon className={styles.fieldIcon} />
      </div>
      <div className={styles.results}>
        {results.map(({ id, direction, modified, content_text }) => (
          <div key={id} tabIndex={0} onClick={handleClick(id)} className={styles.resultsItem}>
            <Text className={styles.date}>{dayJS(modified).format("DD/MM/YYYY")}</Text>
            <Text className={styles.message}>
              {direction === "received" && (
                <>
                  <span className={styles.code}>#{enrolmentsCurrent.student}:</span>{" "}
                </>
              )}
              {showHighlight(content_text).map((text, ind) => (
                <Fragment key={`${ind}-msg`}>{text}</Fragment>
              ))}
            </Text>
          </div>
        ))}
      </div>
    </Sidebar>
  );
};

export default ChatSearch;
