import React from "react";
import { Switch, Route } from "react-router-dom";

import StateProvider from "context/socket";
import PrivateRoute from "utils/private-route";

import Home from "./home";
import Login from "./login";

const App = () => {
  return (
    <StateProvider>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <PrivateRoute path="/" component={Home}></PrivateRoute>
      </Switch>
    </StateProvider>
  );
};

export default App;
