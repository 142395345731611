import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ReactPlayer from "react-player";

import { ReactComponent as AudioIcon } from "images/icons/audio.svg";
import { ReactComponent as PlayIcon } from "images/icons/play.svg";
import { ReactComponent as PauseIcon } from "images/icons/pause.svg";

import styles from "./styles.module.scss";

const Audio = ({ url, className }) => {
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);

  return (
    <div className={classNames(styles.container, className)}>
      <ReactPlayer
        width="0"
        height="0"
        url={url}
        progressInterval={100}
        playing={playing}
        className={styles.audio}
        download={false}
        onReady={() => setLoading(false)}
        onEnded={() => {
          setProgress(100);
          setLoading(true);

          setTimeout(() => {
            setProgress(0);
            setPlaying(false);
            setLoading(false);
          }, 200);
        }}
        onProgress={({ played }) => {
          if (played < 1) return setProgress(played * 100);
        }}
      ></ReactPlayer>
      <div className={styles.player}>
        <button
          onClick={() => setPlaying((oldState) => !oldState)}
          className={classNames(styles.button, {
            [styles.buttonActive]: !playing,
          })}
          disabled={loading}
        >
          {playing ? (
            <PauseIcon className={styles.buttonIcon} />
          ) : (
            <PlayIcon className={styles.buttonIcon} />
          )}
        </button>

        <div className={styles.progress}>
          <span className={styles.progressLine} style={{ width: `${progress}%` }}></span>
          <span
            className={styles.progressIcon}
            style={{ marginLeft: `calc(${progress}% - ${(16 / 100) * progress}px)` }}
          ></span>
        </div>

        <span className={styles.icon}>
          <AudioIcon />
        </span>
      </div>
    </div>
  );
};

Audio.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Audio.defaultProps = {
  className: undefined,
};

export default Audio;
