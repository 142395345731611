import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./styles.module.scss";

const Button = ({ children, className, htmlType, type, disabled, onClick }) => {
  const cssTypes = {
    form: styles.form,
  };

  return (
    <button
      type={htmlType}
      disabled={disabled}
      className={classNames(styles.button, className, cssTypes[type])}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  htmlType: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  className: undefined,
  htmlType: "button",
  type: undefined,
  disabled: false,
  onClick: () => {},
};

export default Button;
