import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Text from "components/atoms/text";

import styles from "./styles.module.scss";

const Field = ({ className, label, name, value, type, required, status, onChange }) => {
  const [initialValue] = useState(value);
  const [isTouched, setTouched] = useState(false);

  const isRequiredEmpty = !value && required && isTouched;
  const statusClassNames = {
    [styles.success]: status === "success",
    [styles.error]: status === "error" || isRequiredEmpty,
  };

  useEffect(() => {
    if (initialValue !== value) setTouched(true);
  }, [initialValue, value]);

  return (
    <label className={classNames(styles.field, className)}>
      {label && (
        <Text weight={600} className={classNames(styles.label, statusClassNames)}>
          {label}
        </Text>
      )}

      <input
        name={name}
        value={value}
        type={type}
        required={required}
        className={classNames(styles.input, statusClassNames)}
        onChange={onChange}
      />
    </label>
  );
};

Field.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  status: PropTypes.oneOf(["error", "success"]),
  onChange: PropTypes.func,
};

Field.defaultProps = {
  className: undefined,
  label: undefined,
  value: undefined,
  type: "text",
  required: false,
  status: null,
  onChange: () => {},
};

export default Field;
