import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

import Button from "components/atoms/button";
import Sidebar from "components/molecules/sidebar";
import { useContext } from "context/socket";
import api from "interfaces/http";

import styles from "./styles.module.scss";

const Templates = ({ toggleIcon, toggleClassName, onSelect }) => {
  const { state } = useContext();
  const { enrolmentsCurrent } = state;

  const [currentCourse, setCurrentCourse] = useState(null);
  const [messages, setMessages] = useState([]);
  const [forceClose, setForceClose] = useState(false);

  const request = useCallback(async () => {
    const { data, status } = await api.get(`/message_templates/?courses=${currentCourse}`);
    if (status < 400 && data.results) setMessages(data.results);
  }, [currentCourse]);

  const close = () => {
    setForceClose(true);
    setTimeout(() => setForceClose(false), 100);
  };

  const handleClick = (message) => (event) => {
    onSelect(message);
    close();
  };

  useEffect(() => {
    if (currentCourse) request();
  }, [currentCourse, request]);

  useEffect(() => {
    close();

    const course = enrolmentsCurrent ? enrolmentsCurrent.course : {};

    if (course.id !== currentCourse) setCurrentCourse(course.id);
  }, [enrolmentsCurrent, currentCourse, request]);

  return (
    <Sidebar
      title="Templates"
      toggleIcon={toggleIcon}
      toggleClassName={toggleClassName}
      forceClose={forceClose}
    >
      <div className={styles.list}>
        {messages.length > 0 &&
          messages.map((message) => (
            <Button key={message.id} className={styles.item} onClick={handleClick(message)}>
              <ReactMarkdown
                source={message.content_text || message.content_url}
                transformLinkUri={() => {}}
              />
            </Button>
          ))}
      </div>
    </Sidebar>
  );
};

Templates.propTypes = {
  toggleClassName: PropTypes.string,
  onSelect: PropTypes.func,
};

Templates.defaultProps = {
  toggleClassName: undefined,
  onSelect: () => {},
};

export default Templates;
