import axios from "axios";
import { getToken } from "utils/store";

const setToken = (data) => {
  let config = data;
  const token = getToken();

  if (token) config.headers.authorization = `Token ${token}`;

  return config;
};

const createClient = () => {
  const baseURL = process.env.REACT_APP_API_URL;
  const client = axios.create({ baseURL });
  client.interceptors.request.use(setToken, Promise.reject);

  return client;
};

const http = createClient();

export default http;
