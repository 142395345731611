import React from "react";

import { useContext } from "context/socket";

import styles from "./styles.module.scss";

const MessagesCounter = () => {
  const { state, dispatch } = useContext();
  const { priorityMessagesCounter } = state;

  const { enrolmentsSearch, enrolmentFilters } = state;
  const hasFilters = Object.values(enrolmentFilters).some((value) => value);

  const handleClick = () => {
    dispatch({ type: "RESET_FILTERS" });
  };

  return (
    priorityMessagesCounter > 0 && (
      <div className={styles.container}>
        <span className={styles.counter}>{priorityMessagesCounter}</span>
        <span className={styles.text}>Novas mensagens</span>
        {(enrolmentsSearch || hasFilters) && (
          <button type="button" onClick={handleClick} className={styles.button}>
            Limpar filtros
          </button>
        )}
      </div>
    )
  );
};

export default MessagesCounter;
