import Cookies from "js-cookie";

export const setUser = ({ token, user, courses }) => {
  if (!user) console.warn(`user cannot be ${user}`);

  Cookies.set("token", token);
  Cookies.set("user", user);
  localStorage.setItem("courses", JSON.stringify(courses));
};

export const getToken = () => {
  const token = Cookies.get("token");

  if (token) return token;

  return null;
};

export const getUser = () => Cookies.getJSON("user");

export const getCourses = () => JSON.parse(localStorage.getItem("courses"));

export const clearUser = () => {
  Cookies.remove("token");
  Cookies.remove("user");
  localStorage.removeItem("courses");
};
