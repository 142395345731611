import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import dayJS from "dayjs";
import ReactMarkdown from "react-markdown";

import Text from "components/atoms/text";
import Audio from "components/molecules/audio";
import Video from "components/molecules/video";
import api from "interfaces/http";
import { ReactComponent as NoPriorityIcon } from "images/icons/no-priority-message.svg";
import { ReactComponent as HighPriorityIcon } from "images/icons/high-priority-message.svg";
import { ReactComponent as DeliveredIcon } from "images/icons/check-sent.svg";
import { ReactComponent as ReadIcon } from "images/icons/check-read.svg";
import { ReactComponent as ErrorIcon } from "images/icons/check-error.svg";

import styles from "./styles.module.scss";

const CSS_TYPES = {
  received: styles.received,
  sent: styles.sent,
};

const Message = forwardRef(
  (
    {
      id,
      direction,
      message_type,
      priority,
      created,
      content_text,
      content_url,
      enrolment,
      status,
      setPriority,
    },
    ref
  ) => {
    const [updatedPriority, setUpdatedPriority] = useState(priority);
    const [updating, setUpdating] = useState(false);

    const handlePriority = async () => {
      const reversePriority = updatedPriority === "HIGH" ? "NONE" : "HIGH";

      setUpdating(true);

      api
        .post("/messages/priority/", { message_ids: id, priority: reversePriority })
        .then(({ status }) => {
          if (status < 400) {
            setUpdatedPriority(reversePriority);
            setPriority(enrolment, id, reversePriority);
          }
          setUpdating(false);
        })
        .catch(() => setUpdating(false));
    };

    const file = () =>
      ({
        image: (
          <a
            href={content_url}
            target="_blank"
            rel="noopener noreferrer"
            className={classNames(styles.image, styles.media)}
          >
            <img src={content_url} alt="" />
          </a>
        ),
        video: <Video className={styles.media} url={content_url} />,
        audio: <Audio className={styles.media} url={content_url} />,
      }[message_type]);

    const checkIcon = {
      sent: "",
      delivered: <DeliveredIcon className={styles.status} />,
      read: <ReadIcon className={styles.status} />,
      undelivered: <ErrorIcon className={styles.status} />,
    };

    return (
      <div
        className={classNames(styles.message, CSS_TYPES[direction], {
          [styles.messageBig]: content_text && content_text.length > 120,
          [styles.messageMedia]: message_type !== "text",
        })}
        ref={ref}
      >
        {direction === "received" && (
          <button className={styles.priority} onClick={handlePriority} disabled={updating}>
            <span className={styles.priorityTooltip}>Prioridade</span>
            <NoPriorityIcon />
            <HighPriorityIcon
              className={classNames(styles.icon, {
                [styles.iconVisible]: updatedPriority === "HIGH",
              })}
            />
          </button>
        )}

        {content_url && content_url.length > 0 && direction === "received" && file()}

        {content_url && content_url.length > 0 && direction === "sent" && file()}

        {content_text && content_text.length > 0 && (
          <Text className={styles.text} markdown>
            <ReactMarkdown
              source={content_text}
              renderers={{
                link: (props) => (
                  <a href={props.href} target="_blank" rel="noopener noreferrer">
                    {props.children}
                  </a>
                ),
              }}
            />
          </Text>
        )}

        {!content_url && !content_text && message_type !== "text" && (
          <Text className={styles.text}>
            <em>Essa mensagem possui conteúdo de mídia que não pode ser exibido.</em>
          </Text>
        )}

        <div className={styles.info}>
          <Text className={styles.time}>{dayJS(created).format("DD/MM/YYYY - HH:mm")}</Text>
          {direction === "sent" && status && checkIcon[status]}
          {(message_type === "video" ||
            message_type === "audio" ||
            message_type === "document") && (
            <a
              className={classNames(styles.downloadButton)}
              href={content_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          )}
        </div>
      </div>
    );
  }
);

Message.propTypes = {
  id: PropTypes.number.isRequired,
  direction: PropTypes.oneOf(["received", "sent"]).isRequired,
  status: PropTypes.oneOf(["sent", "delivered", "read"]),
  message_type: PropTypes.oneOf(["text", "audio", "image", "video"]),
  priority: PropTypes.string,
  created: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  content_text: PropTypes.string,
  content_url: PropTypes.string,
  enrolment: PropTypes.number.isRequired,
  setPriority: PropTypes.func.isRequired,
};

Message.defaultProps = {
  status: undefined,
  message_type: "text",
  priority: undefined,
  content_text: "",
  content_url: "",
};

export default Message;
