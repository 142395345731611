import io from "socket.io-client";

const initSocket = (onMessage = () => {}) => {
  const socket = io(process.env.REACT_APP_WS_URL, { transports: ["websocket"] });

  const emit = ({ enrolment_id, content_text }) => {
    socket.emit("message:send", { enrolment_id: enrolment_id, content_text });
  };

  const close = () => {
    socket.close();
  };

  socket.on("message:new", onMessage);

  return {
    emit,
    close,
  };
};

export default initSocket;
