import React, { useState } from "react";
import PropTypes from "prop-types";

import Field from "components/molecules/field";
import Button from "components/atoms/button";
import { login } from "services/auth";

import styles from "./styles.module.scss";

const ERROR_LIST = {
  401: "E-mail ou senha incorretos.",
};

const LoginForm = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const form = { email, password };

    login(form)
      .then((data) => {
        const { status } = data;
        setLoading(false);
        if (status < 400) onLogin();
      })
      .catch((error) => {
        setLoading(false);

        if (error.message) setError(ERROR_LIST[error.status]);
      });
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <Field
        label="Usuário"
        name="email"
        type="email"
        required
        className={styles.field}
        value={email}
        onChange={({ target }) => {
          setError(undefined);
          setEmail(target.value);
        }}
      ></Field>
      <Field
        label="Senha"
        name="password"
        type="password"
        required
        className={styles.field}
        value={password}
        onChange={({ target }) => {
          setError(undefined);
          setPassword(target.value);
        }}
      ></Field>

      {error && <p className={styles.error}>{error}</p>}

      <Button type="form" htmlType="submit" disabled={loading} className={styles.button}>
        Entrar
      </Button>
    </form>
  );
};

LoginForm.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default LoginForm;
