import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";

import withAnalytics from "components/hocs/analytics";
import "normalize.css";
import "styles/base.scss";

import * as serviceWorker from "utils/service-worker";

import App from "./pages";

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <Route component={withAnalytics(App)} />
    </BrowserRouter>
  </StrictMode>,
  document.getElementById("root")
);

serviceWorker.register();
