import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { logout } from "services/auth";
import { getUser } from "utils/store";
import { ReactComponent as Icon } from "images/icons/options.svg";

import styles from "./styles.module.scss";

const SessionMenu = ({ onLogout }) => {
  const user = getUser() || {};
  const [open, setOpen] = useState(false);

  const displayName = user.first_name ? `${user.first_name} ${user.last_name}` : user.email;

  const handleToggle = () => setOpen(!open);
  const handleLogout = () => {
    setOpen(false);
    logout().then(onLogout);
  };

  return (
    <div
      className={classNames(styles.menu, {
        [styles.isOpen]: open,
      })}
    >
      <div className={styles.toggle} onClick={handleToggle} role="button" tabIndex={0}>
        <span className={styles.user}>{displayName}</span>
        <Icon className={styles.icon} />
        <div className={styles.selection}>
          <button type="button" className={styles.button} onClick={handleLogout}>
            Sair
          </button>
        </div>
      </div>
    </div>
  );
};

SessionMenu.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default SessionMenu;
