import React from "react";

import ChatInfo from "components/molecules/chat-info";
import MessagesList from "components/molecules/messages-list";
import MessageInput from "components/molecules/message-input";
import { useContext } from "context/socket";

import styles from "./styles.module.scss";

const Chat = () => {
  const { state } = useContext();

  return (
    <div className={styles.chat}>
      {state.enrolmentsCurrent && (
        <>
          <ChatInfo {...state.enrolmentsCurrent} />
          <div className={styles.messages}>
            <MessagesList />
            <MessageInput />
          </div>
        </>
      )}
    </div>
  );
};

export default Chat;
