import React from "react";
import PropTypes from "prop-types";

import Text from "components/atoms/text";
import ChatSearch from "components/molecules/chat-search";
import { ReactComponent as Student } from "images/icons/student.svg";
import { ReactComponent as Course } from "images/icons/course.svg";
import { ReactComponent as Class } from "images/icons/class.svg";
import http from "interfaces/http";

import styles from "./styles.module.scss";
// Alteração Talk Tutor
const ChatInfo = ({ id, student, student_token, course, current_lesson, talk_to_tutor }) => {
  const handleCheckboxChange = (element) => {
    const data = {
      enrolment: id,
      talk: element.target.checked,
    };
    http
      .post("/update_talk_to_tutor", { data })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div className={styles.bar}>
      <div className={styles.content}>
        <div className={styles.student}>
          <Student className={styles.studentIcon} />{" "}
          <Text weight={600} className={styles.studentCode}>
            <span>#{student}</span>{" "}
            {student_token && <span className={styles.token}>{student_token}</span>}
          </Text>
        </div>
        <div className={styles.info}>
          {course && (
            <div className={styles.infoItem}>
              <Course className={styles.infoIcon} />{" "}
              <Text className={styles.infoName}>{course.name}</Text>
            </div>
          )}
          {current_lesson && (
            <div className={styles.infoItem}>
              <Class className={styles.infoIcon} />{" "}
              <Text className={styles.infoName}>{current_lesson.name}</Text>
            </div>
          )}
          {"Start Conversation" && (
            <div typeof="button" className={styles.infoItem}>
              <div>
                {talk_to_tutor ? (
                  <Text className={styles.infoName}>
                    Start Conversarion &ensp;{" "}
                    <input
                      id="input_start_conversation"
                      name={id}
                      checked
                      onChange={(e) => handleCheckboxChange(e)}
                      type="checkbox"
                      className={styles.ChatInfo}
                    ></input>
                  </Text>
                ) : (
                  <Text className={styles.infoName}>
                    Start Conversarion &ensp;{" "}
                    <input
                      id="input_start_conversation"
                      name={id}
                      onChange={(e) => handleCheckboxChange(e)}
                      type="checkbox"
                      className={styles.ChatInfo}
                    ></input>
                  </Text>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <ChatSearch />
    </div>
  );
};

ChatInfo.propTypes = {
  id: PropTypes.number.isRequired,
  student: PropTypes.number.isRequired,
  student_token: PropTypes.string,
  course: PropTypes.shape({
    name: PropTypes.string,
  }),
  current_lesson: PropTypes.shape({
    name: PropTypes.string,
  }),
};

ChatInfo.defaultProps = {
  student_token: null,
  course: null,
  current_lesson: null,
};

export default ChatInfo;
