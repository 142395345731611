import { getToken, getUser, clearUser, setUser } from "utils/store";
import api from "interfaces/http";

export const isAuthenticated = () => {
  const token = getToken();
  const user = getUser();

  if (token && user) return true;

  clearUser();

  return false;
};

export const login = async ({ email, password }, onError) => {
  const request = await api
    .post("/login", { email, password })
    .then(({ data, status }) => {
      if (data.access_token)
        setUser({ token: data.access_token, user: data.user, courses: data.courses });

      return { data, status };
    })
    .catch((error) => {
      if (error && error.response) {
        const { data, status } = error.response;
        return Promise.reject({ message: data.detail, status });
      }
      return Promise.reject({ message: error.message, staus: 400 });
    });

  return request;
};

export const logout = () =>
  new Promise((resolve) => {
    clearUser();
    resolve(true);
  });
