import React, { useState } from "react";
import PropTypes from "prop-types";

import Text from "components/atoms/text";
import Dropdown from "components/molecules/dropdown";
import SessionMenu from "components/molecules/session-menu";
import { useContext } from "context/socket";
import { getCourses } from "utils/store";
import { getUser } from "utils/store";
import { getTutorsByCourse } from "services/courses";

import styles from "./styles.module.scss";

const PRIORITIES_LIST = {
  priority: "HIGH",
  medium: "MEDIUM",
  all: null,
};

const Header = ({ onLogout }) => {
  const courses = getCourses() || [];
  const [priority, setPriority] = useState(null);
  const [tutors, setTutors] = useState([]);

  const { state, dispatch } = useContext();
  const {
    enrolmentFilters: { course, tutor },
  } = state;

  const allCourses = { value: null, label: "Todos os cursos" };
  const allTutors = { value: null, label: "Todos os tutores" };

  const setFilter = (field) => (value) => {
    dispatch({ type: "enrolmentS_FILTER", payload: { field, value } });
  };

  const setCourseFilter = async (value) => {
    setFilter("course")(value);
    if (user.is_superuser) {
      setTutors([]);
      const ttrs = await getTutorsByCourse({ course: value });
      setTutors(ttrs);
    }
  };

  const setFilterPriority = (value) => {
    setFilter("priority")(PRIORITIES_LIST[value]);
    setPriority(value);
  };
  const user = getUser();

  return (
    <header className={styles.header}>
      <div className={styles.filters}>
        <div className={styles.filter}>
          <Text weight={600} className={styles.label}>
            Curso:
          </Text>
          <Dropdown
            value={course}
            options={[allCourses, ...courses.map(({ id, name }) => ({ value: id, label: name }))]}
            onSelect={setCourseFilter}
          ></Dropdown>
        </div>
        <div className={styles.filter}>
          <Text weight={600} className={styles.label}>
            Prioridade:
          </Text>
          <Dropdown
            value={priority}
            hasDot
            isHighPriority={priority === "priority"}
            options={[
              {
                value: "all",
                label: "Todas",
              },
              {
                value: "priority",
                label: "Com prioridade",
              },
              {
                value: "medium",
                label: "Prioridade média",
              },
            ]}
            onSelect={setFilterPriority}
          ></Dropdown>
        </div>
        {user.is_superuser && (
          <div className={styles.filter}>
            <Text weight={600} className={styles.label}>
              Tutor:
            </Text>
            <Dropdown
              value={tutor}
              options={[
                allTutors,
                ...tutors.map(({ id, first_name, last_name }) => ({
                  value: id,
                  label: `${first_name} ${last_name}`,
                })),
              ]}
              onSelect={setFilter("tutor")}
            ></Dropdown>
          </div>
        )}
      </div>
      <SessionMenu onLogout={onLogout} />
    </header>
  );
};

Header.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default Header;
