import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Text from "components/atoms/text";
import { ReactComponent as CloseIcon } from "images/icons/close.svg";

import styles from "./styles.module.scss";

const Sidebar = ({ title, children, toggleIcon, toggleClassName, onClose, forceClose }) => {
  const [visible, setVisible] = useState(false);

  const handleClose = (event) => {
    setVisible(false);
    onClose(event);
  };

  useEffect(() => {
    if (forceClose) setVisible(false);
  }, [forceClose]);

  return (
    <div
      className={classNames(styles.container, { [styles.containerVisible]: visible })}
      onClick={({ target, currentTarget }) => {
        if (target === currentTarget) setVisible(false);
      }}
    >
      <button
        type="button"
        className={classNames(styles.toggle, toggleClassName)}
        onClick={() => setVisible((oldState) => !oldState)}
      >
        {toggleIcon}
      </button>

      <div className={classNames(styles.search, { [styles.searchVisible]: visible })}>
        <div className={styles.header}>
          <button className={styles.toggle} onClick={handleClose}>
            <CloseIcon />
          </button>
          <Text weight={600} className={styles.headerTitle}>
            {title}
          </Text>
        </div>
        {children}
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  title: PropTypes.string.isRequired,
  toggleIcon: PropTypes.node.isRequired,
  toggleClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  forceClose: PropTypes.bool,
};

Sidebar.defaultProps = {
  toggleClassName: undefined,
  onClose: () => {},
  forceClose: false,
};

export default Sidebar;
