import React, { createContext, useReducer, useContext } from "react";
import PropTypes from "prop-types";

import { initialState, reducer } from "./reducer";

const StateContext = createContext({});

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <StateContext.Provider value={{ state, dispatch }}>{children}</StateContext.Provider>;
};

StateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useStateContext = () => useContext(StateContext);

export { StateContext };
export { useStateContext as useContext };
export { default as getActions } from "./actions";

export default StateProvider;
