import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Input from "components/atoms/input";
import { ReactComponent as Icon } from "images/icons/dropdown.svg";

import styles from "./styles.module.scss";

const Dropdown = ({ placeholder, value, options, hasDot, isHighPriority, onSelect }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(undefined);

  const handleToggle = () => setOpen(!open);
  const handleClose = () => setOpen(false);

  const handleSelect = (value) => () => {
    onSelect(value);
    handleClose();
  };

  useEffect(() => {
    const selected = options.find(({ value: currentValue }) => value === currentValue);
    setSelected(selected);
  }, [options, value]);

  return (
    <div
      className={classNames(styles.dropdown, {
        [styles.isOpen]: open,
        [styles.dot]: hasDot && !!value,
        [styles.highPriority]: isHighPriority,
      })}
    >
      <div className={styles.toggle} onClick={handleToggle} role="button" tabIndex={0}>
        <Input
          name="dropdown"
          placeholder={placeholder}
          value={selected ? selected.label : ""}
          type="iconRight"
          disabled
          className={classNames(styles.input, {
            [styles.inputFilled]: !!value && !isHighPriority,
            [styles.inputFilledPriority]: !!value && isHighPriority,
          })}
        />
        <Icon className={styles.icon} />
      </div>
      <div className={styles.selection}>
        {options.map(({ value, label }) => (
          <button key={value} type="button" className={styles.button} onClick={handleSelect(value)}>
            {label}
          </button>
        ))}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    })
  ),
  hasDot: PropTypes.bool,
  isHighPriority: PropTypes.bool,
  onSelect: PropTypes.func,
};

Dropdown.defaultProps = {
  placeholder: "Selecionar...",
  value: "",
  options: [],
  hasDot: false,
  isHighPriority: false,
  onSelect: () => {},
};

export default Dropdown;
