const _appliedAnyFilters = (state) => {
  const { enrolmentFilters } = state;
  const { course, priority, tutor } = enrolmentFilters;
  return course || priority || tutor;
};

const hasAnyFiltersApplied = (state) => {
  return _appliedAnyFilters(state);
};

const hasNoFiltersApplied = (state) => {
  return !hasAnyFiltersApplied(state);
};

export { hasAnyFiltersApplied, hasNoFiltersApplied };
