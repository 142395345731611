import request from "utils/request";
import { DEFAULT_MESSAGE_PARAMS } from "./reducer";

const setMessages = (dispatch) => (requestParams) => {
  const type = requestParams.page && requestParams.page > 1 ? "MESSAGES_MERGE" : "MESSAGES";
  const callback = (payload) => dispatch({ type, payload });

  const params = {
    ...DEFAULT_MESSAGE_PARAMS,
    ...requestParams,
    enrolment: requestParams.enrolment,
  };

  request({ path: "/messages/", params, callback });
};

const setCurrentenrolment = (dispatch) => (payload, enrolment = {}) => {
  if (enrolment.messages) dispatch({ type: "CACHED_MESSAGES", payload: enrolment });
  else setMessages(dispatch)({ enrolment: payload.id });

  dispatch({ type: "enrolmentS_CURRENT", payload });
};

const setCurrentPriority = (dispatch) => (enrolment, message, priority) => {
  dispatch({ type: "enrolmentS_CURRENT_COUNTER", payload: { enrolment, message, priority } });
};

const setHighlightedMessage = (dispatch) => (message) => {
  dispatch({ type: "MESSSAGES_HIGHLIGHT", payload: message });
};

const getActions = (dispatch) => ({
  setMessages: setMessages(dispatch),
  setCurrentenrolment: setCurrentenrolment(dispatch),
  setCurrentPriority: setCurrentPriority(dispatch),
  setHighlightedMessage: setHighlightedMessage(dispatch),
});

export default getActions;
