import React, { useState, useEffect } from "react";
import classNames from "classnames";

import Input from "components/atoms/input";
import Templates from "components/molecules/templates";
import { ReactComponent as Icon } from "images/icons/emoji.svg";
import { ReactComponent as TemplatesIcon } from "images/icons/templates.svg";
import { ReactComponent as FileIcon } from "images/icons/file.svg";
import { ReactComponent as SendIcon } from "images/icons/send.svg";
import { ReactComponent as CloseIcon } from "images/icons/close.svg";
import { useContext } from "context/socket";
import api from "interfaces/http";

import styles from "./styles.module.scss";

const FILE_TYPES = [
  // image
  ".jpg",
  ".jpeg",
  ".png",
  // audio
  ".mp3",
  ".ogg",
  ".amr",
  //video
  ".mp4",
  // contact
  ".vcf",
  // others
  ".pdf",
];

const inputFileTypes = FILE_TYPES.join(",");

const MessageInput = () => {
  const { state } = useContext();

  const { socket, enrolmentsCurrent } = state;
  const isOpen = !!enrolmentsCurrent.message_window_open;

  const [message, setMessage] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);
  const [templateSelected, setTemplateSelected] = useState(false);

  const [file, setFile] = useState(null);

  const handleSelectTemplate = ({ content_text, content_url }) => {
    setFile(null);
    setMessage(content_text || content_url);
    setTemplateSelected(true);
  };

  const handleReset = () => {
    setFile(null);
    setMessage("");
    setTemplateSelected(false);
  };

  const handleFileChange = ({ target }) => {
    handleReset();

    const file = target.files[0];
    setFile(file);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const hasValidMessage = message || file;
    const canSendMessage = isOpen || templateSelected;

    if (!hasValidMessage || !socket || !canSendMessage || isSubmitting) return;

    setTemplateSelected(false);
    setSubmitting(true);

    if (file) {
      const form = new FormData();
      form.append("file", file);
      form.append("name", file.name);

      api.post(`/enrolments/${enrolmentsCurrent.id}/media/`, form, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    } else socket.emit({ enrolment_id: enrolmentsCurrent.id, content_text: message });

    setTimeout(() => {
      setFile(null);
      setMessage("");
      setSubmitting(false);
    }, 300);
  };

  useEffect(() => {
    handleReset();
  }, [enrolmentsCurrent]);

  return (
    <form onSubmit={handleSubmit} className={styles.container}>
      <Input
        name="newMessage"
        type="message"
        placeholder="Digitar mensagem..."
        disabled={!isOpen || !socket || templateSelected || !!file}
        className={classNames(styles.input, { [styles.inputTemplate]: templateSelected || file })}
        value={file ? file.name : message}
        maxLength={1600}
        onChange={({ target }) => !templateSelected && setMessage(target.value)}
      />

      <label
        className={classNames(styles.button, styles.file, {
          [styles.fileDisabled]: !isOpen || !socket,
        })}
      >
        <FileIcon className={styles.icon} />
        <input
          type="file"
          name="file"
          accept={inputFileTypes}
          className={styles.fileInput}
          onChange={handleFileChange}
          onClick={({ target }) => {
            target.value = null;
          }}
          disabled={!isOpen || !socket}
        />
      </label>

      <button
        type="button"
        className={classNames(styles.button, styles.close, {
          [styles.closeVisible]: templateSelected,
        })}
        onClick={handleReset}
      >
        <CloseIcon className={styles.icon} />
      </button>

      {isOpen ? (
        <button type="button" className={classNames(styles.button, styles.templates, styles.emoji)}>
          <Icon className={styles.icon} />
        </button>
      ) : (
        <Templates
          toggleIcon={<TemplatesIcon className={styles.icon} />}
          toggleClassName={classNames(styles.button, styles.templates, {
            [styles.templatesHidden]: templateSelected,
          })}
          onSelect={handleSelectTemplate}
        />
      )}

      <button type="submit" className={styles.button}>
        <SendIcon className={styles.iconRight} />
      </button>
    </form>
  );
};

export default MessageInput;
