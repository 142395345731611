import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { isAuthenticated } from "services/auth";

import LoginForm from "components/molecules/login-form";
import logo from "images/logo.png";

import styles from "./styles.module.scss";

const Login = () => {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const handleSubmit = () => setShouldRedirect(true);

  if (shouldRedirect) return <Redirect to="/" />;

  if (isAuthenticated()) setShouldRedirect(true);

  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <img src={logo} alt="LYS Aprendizagem Simplificada" className={styles.logo} />
        <LoginForm onLogin={handleSubmit} />
      </div>
    </div>
  );
};
export default Login;
