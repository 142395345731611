import React, { Component } from "react";

import Message from "components/molecules/message";
import { StateContext, getActions } from "context/socket";

import styles from "./styles.module.scss";

const LOADING_DISTANCE = 62;

class MessagesList extends Component {
  static contextType = StateContext;

  constructor() {
    super();

    this.state = {
      loading: false,
      messagePages: [],
      messagesParams: {},
      messagesNext: null,
      lastMessage: null,
      highlightedMessage: null,
    };

    this.timer = null;
    this.listEnd = null;
    this.container = null;
    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  componentDidMount() {
    this.container.addEventListener("scroll", (ev) => {
      const { messagesNext } = this.state;
      const handleScroll = () => {
        const distance = ev.target.scrollTop;

        if (distance <= LOADING_DISTANCE && !this.state.loading) {
          this.setState({ loading: true });
        }
        clearTimeout(this.timer);
      };

      if (!messagesNext) return this.setState({ loading: false });

      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => handleScroll(ev), 200);
    });
  }

  componentDidUpdate(_, prevState) {
    const { state: contextState } = this.context;
    const {
      messages,
      messagesInfo,
      lastMessage: contextLastMessage,
      highlightedMessage: contextHightlightedMessage,
    } = contextState;
    const params = messagesInfo.params || {};

    const { loading, messagesParams, lastMessage, highlightedMessage } = this.state;

    if (messagesParams.enrolment !== params.enrolment || messagesParams.page !== params.page) {
      this.setState(
        {
          messagePages: messages,
          messagesParams: params,
          messagesNext: messagesInfo.next,
        },
        () => {
          if (params.page === 1) return this.listEnd.scrollIntoView();

          if (this.container.children.length > 1) {
            this.container.children[1].scrollIntoView();
          } else this.container.scrollTo({ top: LOADING_DISTANCE + 1 });

          this.setState({ loading: false });
        }
      );
    }

    if (contextHightlightedMessage !== highlightedMessage && !loading) {
      const messageToScroll = [...messages]
        .flat(1)
        .find(({ id }) => id === contextHightlightedMessage);

      if (messageToScroll || !contextHightlightedMessage) {
        return this.setState({ highlightedMessage: contextHightlightedMessage });
      }

      this.setState({ loading: true });
    }

    if (contextLastMessage && lastMessage !== contextLastMessage) {
      this.setState({ lastMessage: contextLastMessage, messagePages: messages }, () => {
        this.listEnd.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (!prevState.loading && loading) {
      this.handleLoadMore();
    }
  }

  handleLoadMore() {
    const { state: contextState, dispatch } = this.context;
    const { messagesInfo } = contextState;
    const { setMessages } = getActions(dispatch);

    const params = messagesInfo.params || {};
    const { page: currentPage } = params;

    const { messagesParams, messagesNext } = this.state;

    let page = null;
    let retryAttempts = 0;

    if (messagesNext) {
      page = messagesNext.match(/(page=\d*)/);
      page = Number(page[0].replace(/\D/g, ""));
    }

    if (page - 1 === currentPage) {
      const tryRequest = () => {
        retryAttempts += 1;

        if (retryAttempts > 25) return; // 5s trying to get context data
        if (currentPage !== page - 1) return setTimeout(tryRequest, 200);

        return setMessages({ ...messagesParams, page });
      };

      tryRequest();
    }
  }

  messageRef = (el) => {
    const { dispatch } = this.context;
    const { setHighlightedMessage } = getActions(dispatch);

    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
      setHighlightedMessage(null);
    }
  };

  render() {
    const { dispatch } = this.context;
    const { setCurrentPriority } = getActions(dispatch);
    const { messagePages, highlightedMessage } = this.state;

    return (
      <div
        ref={(el) => {
          this.container = el;
        }}
        className={styles.list}
      >
        {messagePages &&
          messagePages
            .map((messages, ind) => (
              <div key={`page-${ind}`} id={`page-${ind}`} className={styles.messagePage}>
                {messages
                  .map((props) => (
                    <Message
                      key={props.id}
                      {...props}
                      ref={props.id === highlightedMessage ? this.messageRef : () => {}}
                      setPriority={setCurrentPriority}
                    />
                  ))
                  .reverse()}
              </div>
            ))
            .reverse()}
        <div
          ref={(el) => {
            this.listEnd = el;
          }}
          className={styles.lastElement}
        ></div>
      </div>
    );
  }
}

export default MessagesList;
