import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./styles.module.scss";

const TYPE_CLASSES = {
  default: styles.default,
  success: styles.success,
  error: styles.error,
};

const Badge = ({ children, type, className }) => (
  <span className={classNames(styles.badge, TYPE_CLASSES[type], className)}>{children}</span>
);

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
};

Badge.defaultProps = {
  type: "default",
  className: undefined,
};

export default Badge;
